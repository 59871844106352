import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Watch } from 'vue-property-decorator';
import { DeviceBasis } from '../../shared/models/dto/devices';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { ODataFilterItem, ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';

@Component({
    components: {
        columnSelectDialog: require('@/views/_components/column-select-dialog/column-select-dialog.vue').default,
        editCreateDeviceDialog: require('@/views/devices/edit-create-device-dialog.vue').default,
        vDataTableFilter: require('@/views/_components/v-data-table-filter/v-data-table-filter.vue').default,
        editSensorDialog: require('@/views/devices/edit-sensor-dialog.vue').default
    }
})
export default class DeviceListComponent extends AuthComponentBase {
    currentRouteName: string = null;
    loading: boolean = true;
    showDialog: boolean = false;
    showSensorDialog: boolean = false;
    showColumnDialog: boolean = false;
    showFilter: boolean = false;
    availableColumnsAreSet: boolean = false;
    id: number = null;
    protocolName: string = '';
    hover: boolean = false;
    expanded: any[] = [];

    deviceId: number = null;
    deviceName: string = '';
    sensorId: number = null;

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['name']
    };

    totalCount: number = 0;
    items: DeviceBasis[] = [];

    headers = [];
    availableHeaders = [];

    returnHeaders() {
        return [
            { text: this.t('Name'), value: 'name', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Description'), value: 'description', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('VendorDeviceName'), value: 'vendorDeviceName', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('ProtocolName'), value: 'protocolName', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Actions'), value: 'actions', sortable: false, width: '160px', }
        ];
    }

    get subHeaders() {
        return [
            { text: this.t('DisplayName'), value: 'displayName', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('Formula'), value: 'formula', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('Actions'), value: 'actions', sortable: false, width: '160px', }
        ];
    }

    @Watch('options', { deep: true, immediate: false })
    onPaginationChanged() {
        this.getItems();
    }

    @Watch('$route', { immediate: true, deep: true })
    onUrlChange(newVal: any) {
        // if the route contains a param, this watcher makes sure the detailpage is opened
        this.currentRouteName = !this.currentRouteName ? newVal.name : this.currentRouteName;
        
        if (newVal && newVal.params && newVal.params.id) {
            if (newVal.path.includes('details')) {
                this.$router.push({ name: newVal.path, params: { id: newVal.params.id, protocolName: newVal.params.protocolName } });
            }
            else {
                this.edit(newVal.params.id, newVal.params.protocolName);
                this.showDialog = true;
            }           
        }
        else {
            this.showDialog = false;
        }
    }

    created() {
        this.headers = this.returnHeaders();
        this.availableHeaders = this.returnHeaders();
    }

    mounted() {
        this.$appHub.$on('update-device-list', this.getItems);
    }

    beforeDestroy() {
        //clean SignalR event
        this.$appHub.$off('update-device-list', this.getItems);
    }

    getItems(filterItems?: ODataFilterItem[]) { // when called from v-data-table-filter, it provides the items
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : [],
            pageIndex: page - 1,
            pageSize: itemsPerPage,
            $count: true,
            expand: ['ITSSensors($expand=ITSSensorEndpoints($count=true;$top=0))']
        };

        if (sortBy.length > 0 && sortBy[0]) {
            queryObj.sortBy = sortBy + ((sortDesc && sortDesc.length > 0 && sortDesc[0]) ? ' desc' : '');
        }

        const query = this.queryBuilder(queryObj);

        this.authService.get<IRestResponseDto<DeviceBasis[]>>(`/api/devices${query}`, false).then((response) => {
            this.items = <DeviceBasis[]><unknown>response.content;
            
            this.totalCount = response.count;
            this.setAvailableColumns();
            this.loading = false;
        });
    }

    deleteItem(id: string) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/devices/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        this.getItems();
                    }
                    else {
                        this.swalToast(2000, 'error', this.t('SomethingWrong'));
                    }
                });
            }
        });
    }

    onRowClick(value) {
        this.details(value.id, value.protocolName);
    }

    details(id: string, protocolName: string) {
        if (id && protocolName) {
            this.$router.push({ name: 'DeviceDetails', params: { id: id, protocolName: protocolName } });
        }
    }

    editITSSensor(deviceId: number, deviceName: string, sensorId: number) {
        this.deviceId = deviceId;
        this.sensorId = sensorId;
        this.deviceName = deviceName;

        this.showSensorDialog = true;
    }

    edit(id: number = null, protocolName: string = null) {
        this.id = id;
        this.protocolName = protocolName ? protocolName : '';
        
        if (id && protocolName) {
            this.$router.push({ name: this.currentRouteName, params: { id: id.toString(), protocolName: protocolName } });
        }

        this.showDialog = true;
    }

    completed(reload: boolean) {
        this.$router.push({ name: this.currentRouteName });
        this.showDialog = false;
        if (reload) {
            this.getItems();
        }
    }

    setAvailableColumns() {
        if (this.items.length) {
            for (const [key, value] of Object.entries(this.items[0])) {
                if (!this.availableHeaders.some(e => e.value === key)) {
                    this.availableHeaders.push({
                        text: key !== 'id' ? this.t(key) : key,
                        value: key,
                        sortable: true,
                        width: 'auto',
                        filterOperator: this.returnFilterOperator(this.items[0], key),
                        inputType: this.returnPropType(this.items[0], key),
                    });
                }
            }
        }

        this.availableColumnsAreSet = true;
    }

    columnSelectCompleted(headers: any) {
        if (headers != null) {
            this.headers = [...headers];
        }

        this.showColumnDialog = false;
    }
}