export enum PropertyType {
    ptEmpty = 0,
    ptObject = 1,
    ptDBNull = 2,
    ptBoolean = 3,
    ptChar = 4,
    ptSByte = 5,
    ptByte = 6,
    ptInt16 = 7,
    ptUInt16 = 8,
    ptInt32 = 9,
    ptUInt32 = 10,
    ptInt64 = 11,
    ptUInt64 = 12,
    ptSingle = 13,
    ptDouble = 14,
    ptDecimal = 15,
    ptDateTime = 16,
    unused= 17,
    ptString = 18,
}

export enum EndpointType {
    OPCUAINTERNAL = "OpcUAInternal",
    OPCDA = "OpcDA",
    Modbus = "Modbus",
    Profinet = "Profinet",
    OPCUAEXTERNAL = "OpcUAExternal"
}