import { SelectListItem } from '../models/shared/common';

const StringIsNotNumber = value => isNaN(Number(value));

export default class EnumHelper {
    public static async ConvertToSelectList(enumType): Promise<SelectListItem[]> {
        return Object.values<string>(enumType)
            .filter(StringIsNotNumber)
            .map(key => <SelectListItem>({ text: key, value: enumType[key] }));
    }
}