var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.t('Devices')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",class:_vm.showFilter ? 'v-item--active v-btn--active elevation-0' : '',attrs:{"icon":""},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('Filters')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.showColumnDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-view-column")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('SelectColumns')))])]),(_vm.AppConsts.auth.isGranted('Permissions_Device_Create'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-4",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.edit()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3455052910)},[_c('span',[_vm._v(_vm._s(_vm.t('NewDevice')))])]):_vm._e()],1),_c('v-data-table',{staticClass:"rowHoverPointer",attrs:{"headers":_vm.headers,"items":_vm.items,"show-expand":"","expanded":_vm.expanded,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading},on:{"click:row":_vm.onRowClick,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('v-data-table-filter',{attrs:{"headers":_vm.headers,"show-filter":_vm.showFilter},on:{"filterChanged":_vm.getItems}})]},proxy:true},{key:"item.isSystemDefault",fn:function(ref){
var item = ref.item;
return [(item.isSystemDefault === true)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.AppConsts.auth.isGranted('Permissions_Device_Update'))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":[function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRowClick($event)},function($event){return _vm.edit(item.id, item.protocolName)}]}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.AppConsts.auth.isGranted('Permissions_Device_Delete'))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"align-end":"","icon":"","small":""},on:{"click":[function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRowClick($event)},function($event){return _vm.deleteItem(item.id)}]}},[_c('v-icon',{class:hover ? 'red--text' : ''},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"6"}},[_c('div',[_c('v-data-table',{staticClass:"ma-3",attrs:{"headers":_vm.subHeaders,"items":item.iTSSensors,"dense":"","hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.AppConsts.auth.isGranted('Permissions_ITSSensor_Update'))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":item['iTSSensorEndpoints@odata.count'] > 0 ? 'success' : '',"small":""},on:{"click":function($event){return _vm.editITSSensor(item.iTSDeviceId, item.displayName, item.sensorId)}}},[_c('v-icon',[_vm._v("mdi-tag-multiple")])],1):_vm._e()]}}],null,true)})],1)])]}}])}),_c('edit-create-device-dialog',{attrs:{"show":_vm.showDialog,"id":_vm.id,"protocol-name":_vm.protocolName},on:{"completed":_vm.completed}}),(_vm.availableColumnsAreSet)?_c('column-select-dialog',{attrs:{"show":_vm.showColumnDialog,"columns":_vm.headers,"available-columns":_vm.availableHeaders},on:{"completed":_vm.columnSelectCompleted}}):_vm._e(),_c('edit-sensor-dialog',{attrs:{"show":_vm.showSensorDialog,"device-id":_vm.deviceId,"device-name":_vm.deviceName,"sensor-id":_vm.sensorId},on:{"completed":function($event){_vm.showSensorDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }